import { useState, useEffect } from 'react';
import { getBoundary } from 'vibemap-constants/dist/helpers.js'


const useFetchBoundary = (
    showBoundary,
    cityCurrent,
    setBoundariesCallback = () => { },
    cache_bust = true,
) => {
    const [boundaries, setBoundaries] = useState(null);
    const [geoJSON, setGeoJSON] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (showBoundary && cityCurrent && (boundaries == null || boundaries.length === 0)) {
                const fetchBoundary = async () => {
                try {
                    const boundaryObject = await getBoundary(cityCurrent?.slug, cache_bust);
                    const boundaries = boundaryObject?.the_geom;
                    setBoundaries(boundaries);
                    setBoundariesCallback(boundaries);

                    // Is there a custom geoJSON object?
                    const hasBoundaryFeature = boundaryObject?.polygons && boundaryObject?.polygons?.features?.length > 0

                    const polygonsGeoJSON = hasBoundaryFeature
                        ? boundaryObject.polygons
                        : null;

                    if (polygonsGeoJSON && polygonsGeoJSON != []) {
                        setGeoJSON(polygonsGeoJSON);
                    }
                } catch (error) {
                    console.error('Failed to fetch boundary:', error);
                    setError(error);
                }
            };

            fetchBoundary();
        }
    }, [showBoundary, cityCurrent, boundaries]);

    return {
        boundaries,
        geoJSON,
        error
    }
}

export default useFetchBoundary;